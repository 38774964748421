<template>
    <div class="hospitals">
    <PreLoader v-if="isLoading"></PreLoader>
        <v-card>
          <v-card-title>
          </v-card-title>
        <v-data-table
        :headers="headers"
        :items="hospitals"
        :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat>
            <v-row>
              <v-col md="3" sm="3">
                <v-toolbar-title>Список Grammar тестов</v-toolbar-title>
              </v-col>
              <v-col md="2" sm="2">
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Поиск..."
                
                hide-details
                ></v-text-field>
              </v-col>
              <v-col md="2" sm="2">
                <v-select v-model="locationId" :items="locations" item-text="title" item-value="id"  label="Город/Область"></v-select>
              </v-col>
              <v-col md="5" sm=5>
                <v-btn color="#F53C56"  class="hospitals__addbtn mb-2 mr-5" :to="{path: '/hospital/add'}">Добавить <v-icon>mdi-arrow-up</v-icon></v-btn>
                <v-btn color="#40D6F2"  class="hospitals__btn mb-2 mr-5">Импорт</v-btn>
                <v-btn color="#7764E4" class="hospitals__btn mb-2 mr-5">Экспорт</v-btn>
              </v-col>
            </v-row>
            </v-toolbar>
          </template>
          <template>
            
          </template>
          <template v-slot:item.action="{ item }">
            <!--<v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-content-save-edit-outline
            </v-icon>-->

            <v-dialog :retain-focus="false"
              v-model="dialog2"
              max-width="500"
            >
            <template v-slot:activator="{ on }">
              <v-icon
                md v-on="on"
                @click="dialog2 =true"
                color="#F53C56"
              >
                mdi-delete
              </v-icon>
            </template>
            
              <v-card>
                <v-card-title class="headline">
                  Вы действительно хотите удалить?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="dialog2=false"
                  >
                    Отменить
                  </v-btn>

                  <v-btn
                    color="green darken-1"
                    text
                    @click.stop="deleteHospital(item)"
                  >
                    Удалить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          
        </v-data-table>
        
    </v-card>
    </div>
</template>
<script>
import PreLoader from '../../components/preloader/PreLoader.vue'
export default {
    components: {
    PreLoader,
  },
  name: "Hospitals",
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Название больницы',
            align: 'start',
            value: 'title',
          },
          { text: 'Область', value: 'address' },
          { text: 'Город/район', value: 'locationTitle' },
          { text: 'Эл. Почта', value: '' },
          { text: 'Тел.', value: '' },
          { text: 'Адрес', value: 'address' },
          { text: '', value: 'action',sortable: false },
          
          
        ],
        hospital: 
          {
            address: '',
            locationTitle:'',
            title:''
          },
        hospitals: [],
        locations:[],
        location:{

        },
        close: false,
        dialog2:false,
        dialog:false,
        locationId:0,
        isLoading:false
      }
    },
    created() {
    },           
    methods: {
        fetchHospitals () {
            this.isLoading =true;
           
        },
        fetchLocations () {
            
            this.isLoading = true;
            
        },
        onChangeLocation3 (){
            
            console.log(this.locationId);
                
        },
        deleteHospital () 
        { 
            
        },
        edit(item) {
          this.$router.push({ name: 'TestEdit', params: { testId: item.id } });
        },
        
    },
}
</script>
<style lang="scss" scoped>
.hospitals{
  &__btn{
    color:#FFFFFF;
  }
  &__addbtn{
    color:#FFFFFF;
  }
}
</style>